import { configureStore } from '@reduxjs/toolkit';
import obraReducer from './reducers/selectorObra';
import barreraReducer from './reducers/selectorBarreras';
import mostradorComponentes from './reducers/componentesHome';
import refrescar from './reducers/refrescar';

export const store = configureStore({
  reducer: {
    selectorObra: obraReducer,
    selectorBarreras: barreraReducer,
    mostradorComponentes: mostradorComponentes,
    refrescar: refrescar,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch