
export const reordenarComponentes = (ref, comp) => {
  let nuevoArray = [];
  for(let i = 0; i < comp.length; i++){
    for(let j = 0; j < comp.length; j++){
      if(ref[i] === comp[j].id){
        nuevoArray.push(comp[j]);
        break;
      }
    }
  }

  return nuevoArray;
}