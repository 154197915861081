import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardHeader, 
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
  Slide,
} from '@mui/material';
import NoMeetingRoomSharpIcon from '@mui/icons-material/NoMeetingRoomSharp';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CachedIcon from '@mui/icons-material/Cached';
import { useEffect, useState, useRef } from "react";
import { fetchBarreras, fetchSetBarrera } from "../reducers/fetchWebService";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../store';
import { setBarreras } from "../reducers/selectorBarreras";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close'; 
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp'; // tres puntitos verticales
import ZoomOutSharpIcon from '@mui/icons-material/ZoomOutSharp';
import ZoomInSharpIcon from '@mui/icons-material/ZoomInSharp';
import { useCookies } from "react-cookie";
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    stickyHeader: {
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
      zIndex: 999
    },
    rowChico: {
      width: 50,
      [theme.breakpoints.up('sm')]: {
        width: 100
      },
      [theme.breakpoints.up('md')]: {
        width: 50,
      },
      [theme.breakpoints.up('lg')]: {
        width: 100,
      },
      [theme.breakpoints.up('xl')]: {
        width: '80%',
      },
    },
    rowGrande: {
      width: 50,
      [theme.breakpoints.up('sm')]: {
        width: 100,
      },
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
    }
  }),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '2px 0px',
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '2px 0px',
}

const rowHeight = 40;

export default function Barreras(props) {
  const classes = useStyles();
  const { columnas } = useSelector((state: RootState) => state.mostradorComponentes)
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const { refresco } = useSelector((state: RootState) => state.refrescar);
  const { barreras } = useSelector((state: RootState) => state.selectorBarreras);
  const navigate = useNavigate();
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(true);
  const [cookies] = useCookies();
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const lengthPrevia = useRef(0);

  const handleBarrera = async (comando, idBarrera, barrera, estadoBarrera) => {
    let idDevice = idBarrera;
    let idUsuario = cookies.Id_User; 
    
    let nuevaBarrera= {
      Id: barrera.Id,
      Descripcion: barrera.Descripcion,
      Estado: barrera.Estado,
      Conexion: barrera.Conexion,
      ComandoPendiente: comando
    };
    
    if(await fetchSetBarrera(idDevice, comando, idUsuario)){
      let barrerasTemp1 = barreras;
      let barrerasTemp2 = [];
      
      barrerasTemp1.forEach((barrera) => {
        if (barrera.Id === idBarrera){
          barrera = nuevaBarrera;
        }
        barrerasTemp2.push(barrera);        
      });
      await dispatch(setBarreras(barrerasTemp2));
    }else{
      alert('ERROR al manipular barrera')
    }
  }
  
  const traerBarrerasDeObra = async () => {
    let payload = await fetchBarreras(obra.Barreras_Dispositivos);
    await dispatch(setBarreras(payload));
    setCargando(true);
    lengthPrevia.current = payload.length;
    setUltimoRefresco(new Date().toTimeString());
    setCargando(false);
    
    if (rowSelectIndex != undefined) {
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  };

  const verificarNuevosDatos = async () => {
    let payload = await fetchBarreras(obra.Barreras_Dispositivos);
    payload.forEach((pBarrera, i) => {
      if(pBarrera.Estado !== barreras[i].Estado || pBarrera.Conexion !== barreras[i].Conexion || pBarrera.ComandoPendiente !== barreras[i].ComandoPendiente){
        console.log('TRAER');

        traerBarrerasDeObra();
      }
    });
  }

  useEffect(() => {
    traerBarrerasDeObra();
  }, [obra])

  useEffect(() => {
    if(props.isVisible){
      if(!cargando) {
        verificarNuevosDatos();
      }
    }else{
      traerBarrerasDeObra();
    }
  }, [refresco]);

  return (
    <Card sx={{ minWidth: 100 }} elevation={5} id="2">
    <CardHeader
        action={
          <ButtonGroup variant="text" aria-label="outlined primary button group">
            <Button size="small" onClick={() => traerBarrerasDeObra()}><CachedIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasFull()}><ZoomInSharpIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasHalf()}><ZoomOutSharpIcon/></Button>
            <Button size="small" onClick={() => navigate('/barreras')}><FullscreenIcon/></Button>
            <Button size='small' onClick={() => props.handleHide()}><CloseIcon/></Button>
          </ButtonGroup>
        }
        title="Barreras"
      >
      </CardHeader>
      <CardContent>
        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 450 }}>
          <Table aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
                <TableCell style={{ paddingRight: 2 }}>
                  <Box style={{ maxWidth: '80%' }}>
                    <Tooltip title='Dispositivo'>
                      <Typography noWrap align="left" sx={{ fontSize: '0.875rem' }}>Dispositivo</Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell style={{ paddingLeft: 2, paddingRight: 2, maxWidth: '80%'}} align="left">
                  <Box style={{ maxWidth: '50%' }}>
                    <Tooltip title='Descripción'>
                      <Typography noWrap align="left" sx={{ fontSize: '0.875rem' }}>Descripción</Typography>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell style={{ paddingLeft: 2, paddingRight: 2}} align="center">Online/Offline</TableCell>
                <TableCell style={{ paddingLeft: 2, paddingRight: 2}} align="center">Estado</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando &&
               (barreras.map((barrera, index: any) => (
                <TableRow
                  key={index}
                  id={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: rowHeight }}
                  // selected={index === rowSelectIndex ? true : false}
                  onClick={() => {
                    setRowSelectIndex(index);
                  }}
                >
                  <TableCell width='10%' style={{ padding: '5px 10px', paddingRight: 2, maxWidth: '15%' }} align="left" component="th" scope="row">
                    {barrera.Id}
                  </TableCell>
                  <TableCell style={{ padding: '5px 0px' }} width='100%' align="left">
                    <Box style={{ maxWidth: '100%' }}>
                      <Tooltip title={barrera.Descripcion}>
                        <Typography className={columnas[1] == 12 ? classes.rowGrande : classes.rowChico} noWrap align="left">{barrera.Descripcion}</Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {barrera.Conexion === 'OFFLINE' ?
                  <TableCell width='15%' align="center" style={estiloRojo}><p style={{ margin: 2 }}>{barrera.Conexion}</p></TableCell>
                  :
                  <TableCell width='15%' align="center" style={estiloVerde}><p style={{ margin: 2 }}>{barrera.Conexion}</p></TableCell>
                  }
                  <TableCell width='15%' style={{ padding: '5px 10px' }} align="center">
                    {barrera.Estado === 'Abierta' 
                    ? <img src={"./barrera-abierta.png"} alt="Barrera Abierta"  />
                    : <img src={"./barrera-cerrada.png"} alt="Barrera Cerrada"  />}
                  </TableCell>
                  {
                    barrera.Conexion === 'OFFLINE' ?
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row"></TableCell>
                    :
                    <TableCell style={{ padding: 0, width: '0%', zIndex: 1 }} align="center">
                    <Button 
                      variant='outlined' 
                      size='small' 
                      disableElevation 
                      disabled={ barrera.ComandoPendiente === "1" ? true : false } 
                      onClick={() => {handleBarrera('1', barrera.Id, barrera, 'Abierta');}} 
                      style={{ height: rowHeight, borderRadius: 3 }}
                    >
                      <Typography style={{ fontSize: '0.7rem'}}>Abrir</Typography>
                    </Button>
                  </TableCell>
                  }
                  {
                    barrera.Conexion === 'OFFLINE' ?
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row"></TableCell>
                    :
                    <TableCell style={{ padding: 0, width: '0%', zIndex:1 }} align="center">
                    <Button 
                      variant='outlined' 
                      size='small' 
                      disableElevation
                      disabled={ barrera.ComandoPendiente === "0" ? true : false }  
                      onClick={() => {handleBarrera('0', barrera.Id, barrera, 'Cerrada');}} 
                      style={{ height: rowHeight, borderRadius: 3 }}
                    >
                      <Typography style={{ fontSize: '0.7rem'}}>Cerrar</Typography>
                    </Button>
                  </TableCell>
                  }
                  {
                    barrera.Conexion === 'OFFLINE' ?
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row"></TableCell>
                    :
                    <TableCell style={{ padding: 0, width: '0%', zIndex: 1 }} align="center">
                    <Button 
                      variant='outlined' 
                      size='small' 
                      disableElevation
                      disabled={ barrera.ComandoPendiente === "2" ? true : false }  
                      onClick={() => {handleBarrera('2', barrera.Id, barrera, 'Abierta');}} 
                      style={{ height: rowHeight, borderRadius: 3 }}
                    >
                      <Typography style={{ fontSize: '0.7rem'}}>Dejar Abierta</Typography>
                    </Button>
                  </TableCell>
                  }
                </TableRow>
              )))
            }
          </TableBody>
          </Table>
          {cargando && <Box sx={{
          width: '100%',
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          margin: '1rem 0rem',
        }}>
          <CircularProgress/>
        </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}