import { createSlice } from "@reduxjs/toolkit";

export const refrescarSlice = createSlice({
  name: 'refrescar',
  initialState: {
    refresco: null,
  },
  reducers: {
    cambiarRefresco: (state, action) => {
      state.refresco = action.payload;
    },
  }
})

export const { cambiarRefresco } = refrescarSlice.actions;

export default refrescarSlice.reducer;