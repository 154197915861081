import { createSlice } from "@reduxjs/toolkit";

export const componentesSlice = createSlice({
  name: 'mostradorComponentes',
  initialState: {
    indexCookie: 0,
    abiertos: [],
    columnas: [
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
      6,
    ],
  },
  reducers: {
    setIndex: (state, action) => {
      state.indexCookie = action.payload;
    },
    addComponente: (state, action) => {
      state.abiertos.push(action.payload);
    },
    delAbierto: (state, action) => {
      state.abiertos = state.abiertos.filter((comp) => comp !== action.payload);
    },
    setColumasMitad: (state, action) => {
      state.columnas.forEach((col, index) => {
        if(index === action.payload){
          state.columnas[index] = 6;
        }
      })
    },
    setColumasFull: (state, action) => {
      state.columnas.forEach((col, index) => {
        if(index === action.payload){
          state.columnas[index] = 12;
        }
      })
    },
    setAllAbiertos: (state, action) => {
      state.abiertos = action.payload;
    },
    setAllColumnas: (state, action) => {
      state.columnas = action.payload;
    },
  }
})

export const { setIndex, addComponente, delAbierto, setColumasMitad, setColumasFull, setAllAbiertos, setAllColumnas } = componentesSlice.actions;

export default componentesSlice.reducer;