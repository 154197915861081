/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateRange } from '@mui/lab/DateRangePicker';
import Select from '@mui/material/Select';
import type { RootState } from '../store';
import DatePicker from '@mui/lab/DatePicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardHeader,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Box,
  TextField,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import ZoomOutSharpIcon from '@mui/icons-material/ZoomOutSharp';
import ZoomInSharpIcon from '@mui/icons-material/ZoomInSharp'
import { fetchMovimientosVehiculoTotem } from '../reducers/fetchWebService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IngresoPeatonal from "../components/IngresoPeatonal";
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { makeStyles, createStyles } from '@mui/styles';
import { es } from "date-fns/locale";

const style = {
  position: 'relative' as 'relative',
  top: '50%',
  left: '50%',
  bottom: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '1rem',
  overflow: 'auto'
};

const useStyles = makeStyles(() => 
  createStyles({
    stickyHeader: {
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
      zIndex: 999
    },
  }),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '2px 10px',
}

const estiloAmarillo = {
  color: "white",
  backgroundColor: "#FFA600",
  padding: '2px 10px',
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '2px 10px',
}

export default function MovimientoVehiculoTotemsEntradaComponente(props) {
  const classes = useStyles();
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const { refresco } = useSelector((state: RootState) => state.refrescar);
  const [fecha, setFecha] = React.useState<DateRange<Date>>([null, null]);
  const navigate = useNavigate();
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const dispatch = useDispatch();
  const [cargando, setCargando] = useState(true);
  const lengthPrevia = useRef(0);
  const [cookies] = useCookies();
  
  const [movimientos, setMovimientos] = useState<any>([]);

  const hoy = new Date();
  const [hoyString, setHoyString] = useState<string>();
  const [fechaMovimientoDesde, setFechaMovimientoDesde] = React.useState<Date | null>(hoy);
  const [fechaMovimientoHasta, setFechaMovimientoHasta] = React.useState<Date | null>(hoy);
  const [fechaMovimientoDesdeString, setFechaMovimientoDesdeString] = useState<string>(hoyString);
  const [fechaMovimientoHastaString, setFechaMovimientoHastaString] = useState<string>(hoyString);
  const [licenseBusqueda, setLicenseBusqueda] = useState<string>('');
  const [refrescarBusqueda, setRefrescarBusqueda] = useState<boolean>(true);
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  const radioSelectIndex = useRef(-1);

  const setearTotemsSegunObra = () => {
    //los totems pueden ir separados por coma, ej: '28,29,30' 
    let totemsEntrada = obra.Totems.filter((totem) => totem.Tipo === "E");
    let codigosTotems = [];
    totemsEntrada.map(totem => (
      codigosTotems.push(totem.Id_Totem)
    ));
    return codigosTotems.join();
  };

  const [orderColumn, setOrderColumn]= useState("Hora");
  const [orderDirection, setOrderDirection]= useState("desc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Hora") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(movimientos, columnName, columnOrder);

    setMovimientos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async (diff?) => {
    let totemsTemP = setearTotemsSegunObra();
    let idUsuario = cookies.Id_User; 
    
    fechaHoyString();

    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    let payload = await fetchMovimientosVehiculoTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, licenseBusqueda, totemsTemP, obra.Id_Zone, idUsuario);

    lengthPrevia.current = payload.length;
    
    setCargando(true);
    setMovimientos([]);
    setMovimientos(payload);
    
    if(diff) {
      let rowNuevo = radioSelectIndex.current+diff
      radioSelectIndex.current = rowNuevo;
    }
    setCargando(false);
    setUltimoRefresco(new Date().toTimeString());
    if (rowSelectIndex != undefined) {
      let element = document.getElementById(rowSelectIndex.toString());
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }

  const verificarNuevosDatos = async () => {
    let totemsTemP = setearTotemsSegunObra();
    let idUsuario = cookies.Id_User; 

    let payload = await fetchMovimientosVehiculoTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, licenseBusqueda, totemsTemP, obra.Id_Zone, idUsuario);
    
    if(payload.length !== lengthPrevia.current){
      let diff = payload.length - lengthPrevia.current;
      leer(diff);
    }
  }
  
  useEffect(() => {
    fechaHoyString();
    leer();
  }, [refrescarBusqueda, obra]);

  useEffect(() => {
    if(props.isVisible){
      if(!cargando)
      verificarNuevosDatos();
    }
  }, [refresco]);

  const fechaHoyString = () => {
    let diaHoy = hoy.getDate();
    let mesHoy = hoy.getMonth();
    let yearHoy = hoy.getFullYear();

    if (mesHoy<10 && diaHoy<10){
      setHoyString(`0${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy>=10 && diaHoy<10){
      setHoyString(`0${diaHoy}/${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy<10 && diaHoy>=10){
      setHoyString(`${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else{
      setHoyString(`${diaHoy}/${mesHoy}/${yearHoy}`);
    }
  }

  
  const fechaMovimientoDesdeAString = ()=>{
    let dia = fechaMovimientoDesde.getDate();
    let mes = fechaMovimientoDesde.getMonth() + 1;
    let year = fechaMovimientoDesde.getFullYear();

    if (mes<10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/0${mes}/${year}`);
    }
    else if (mes>=10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/${mes}/${year}`);
    }
    else if (mes<10 && dia>=10){
      setFechaMovimientoDesdeString(`${dia}/0${mes}/${year}`);
    }
    else{
      setFechaMovimientoDesdeString(`${dia}/${mes}/${year}`);
    }
  }

  const fechaMovimientoHastaAString = ()=>{
    let diaH = fechaMovimientoHasta.getDate();
    let mesH = fechaMovimientoHasta.getMonth() + 1;
    let yearH = fechaMovimientoHasta.getFullYear();

    if (mesH<10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/0${mesH}/${yearH}`);
    }
    else if (mesH>=10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/${mesH}/${yearH}`);
    }
    else if (mesH<10 && diaH>=10){
      setFechaMovimientoHastaString(`${diaH}/0${mesH}/${yearH}`);
    }
    else{
      setFechaMovimientoHastaString(`${diaH}/${mesH}/${yearH}`);
    }
  }

  //Pasa los metodos que pasan las fechas de Date a string para poder hacer la petición, 
  //luego llama al metodo que cambia el estado de refrescarBusqueda para que useEffect se active
  const fechasAString = () => {
    setCargando(true);
    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    botonBuscarRefrescarBusqueda();
  }

  const botonBuscarRefrescarBusqueda = () => {
    if(refrescarBusqueda===true){
      setRefrescarBusqueda(false);
    }
    else if (refrescarBusqueda===false){
      setRefrescarBusqueda(true);
    }
  }

  const handleChangeLicenseBusqueda = e => {
    setLicenseBusqueda(e.target.value);
  }

  return (
    <Card sx={{ minWidth: 100 }} elevation={5} id="7">
      <CardHeader
        action={
          <ButtonGroup variant="text" aria-label="outlined primary button group">
            <Button size="small" disabled={cargando} color='info' variant='text' onClick={() => leer()}><CachedIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasFull()}><ZoomInSharpIcon/></Button>
            <Button size="small" onClick={() => props.setColumnasHalf()}><ZoomOutSharpIcon/></Button>
            <Button size="small" onClick={() => navigate('/MovimientoVehiculoTotemsEntrada')}><FullscreenIcon/></Button>
            <Button size='small' onClick={() => props.handleHide()}><CloseIcon/></Button>
          </ButtonGroup>
        }
        title="Entrada de Vehiculos"
      ><CachedIcon/>
      </CardHeader>
      <CardContent>
        <FormControl fullWidth sx={{ display: "flex", flexDirection: 'row'}}>
          <Box sx={{ m: 1, width: '50%' }}>
            <InputLabel>Obra</InputLabel>
            <Select
              label="Obra"
              value={obra.Id_Zone.toString()}
              fullWidth
              disabled
              size='small'
            >
              <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
            </Select>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <TextField
              margin="none"
              fullWidth
              value={licenseBusqueda}
              onChange={handleChangeLicenseBusqueda}
              id="license"
              label="Patente"
              name="license"
              autoComplete="license"
              size='small'
            />
          </Box>
        </FormControl>
        <Stack sx={{ display: "flex", flexDirection: 'row', width: '100%' }}>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoDesde}
                  onChange={
                    (newValue) => {
                      setFechaMovimientoDesde(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoHasta}
                  onChange={(newValue) => {
                    setFechaMovimientoHasta(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Stack>
      
      <Button onClick={()=>{fechasAString();}}>Buscar</Button>

      <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 450 }}>

      <FormControl fullWidth>
      <RadioGroup>      

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Hora')}>
                    <TableSortLabel active={orderColumn == 'Hora'} direction={orderColumn != 'Hora' || orderDirection === "asc"? "asc" : "desc"}>
                    Hora
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Vehicle')}>
                    <TableSortLabel active={orderColumn == 'Vehicle'} direction={orderColumn != 'Vehicle' || orderDirection === "asc"? "asc" : "desc"}>
                    Vehiculo
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                    <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                    Contratista
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Vigencia_Descripcion')}>
                    <TableSortLabel active={orderColumn == 'Vigencia_Descripcion'} direction={orderColumn != 'Vigencia_Descripcion' || orderDirection === "asc"? "asc" : "desc"}>
                    Estado
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Barrera')}>
                    <TableSortLabel active={orderColumn == 'Barrera'} direction={orderColumn != 'Barrera' || orderDirection === "asc"? "asc" : "desc"}>
                    Totem
                    </TableSortLabel>                    
                  </TableCell>
                  <TableCell onClick={(e)=> handleSortRequest('Motivo')}>
                    <TableSortLabel active={orderColumn == 'Motivo'} direction={orderColumn != 'Motivo' || orderDirection === "asc"? "asc" : "desc"}>
                    Requisito Faltante
                    </TableSortLabel>                    
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (movimientos.map((movimiento, index) => (
                  <TableRow
                    key={index}
                    id={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    selected={index === radioSelectIndex.current ? true : false}
                    onClick={() => {
                      setRowSelectIndex(index);
                    }}
                  >
                    <TableCell style={{ padding: '2px 10px' }} component="th" scope="row">
                      {movimiento.Hora}
                    </TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">{movimiento.Vehicle}</TableCell>
                    
                    {movimiento.Contratista === "- Otra (Pendiente de alta)" 
                    ? <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2 }}>{movimiento.Contratista}</p></TableCell>
                    : <TableCell style={{ padding: '2px 10px' }} align="right">{movimiento.Contratista}</TableCell>
                    }
                    
                    {movimiento.Vigencia_Descripcion === 'Apto' ?
                    <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                    :
                    <TableCell align="center" style={movimiento.Motivo !== '' ? estiloAmarillo : estiloRojo}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                    }

                    <TableCell style={{ padding: '2px 10px' }} align="right">{movimiento.Barrera}</TableCell>
                    <TableCell style={{ padding: '2px 10px' }} align="right">
                      <Box style={{ maxWidth: '15vw' }}>
                        <Tooltip title={movimiento.Motivo}>
                          <Typography noWrap align="left">{movimiento.Motivo}</Typography>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                  )))
              }
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}

        </RadioGroup>
        </FormControl>
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}