interface ApiError {
  message: string;
  statusCode?: number;
}

export const fetchApi = async (
  url: string,
  options: RequestInit,
): Promise<[Record<string, any> | null, ApiError | null]> => {
  try {
    const response = await fetch(url, options || {});
    const body = await response.json();
    
    if (!response.ok) {
      return [null, body];
    }

    return [body, null];
  } catch (error: any) {
    return [null, { message: error.message }];
  }
};
