import MainAdminItems from './list-items-admin';
import { ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip, 
  Typography} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Link, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LogoutIcon from '@mui/icons-material/Logout';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  })
);

export const MainListItems = () => {

  return (
    <div>
      <MainAdminItems />
    </div>
  );
};

export const SecondaryListItems= () => {
  const classes = useStyles();

  return (
    <div>
      <ListSubheader inset></ListSubheader>
      {/* otro */}
      <Link to='/permisoIngresoPendiente' className={classes.link}>
        <Tooltip title='Permisos de Ingresos Pendientes' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <PendingActionsIcon />
            </ListItemIcon>
            <ListItemText primary="Ingresos Pendientes" />
          </ListItem>
        </Tooltip>
      </Link>
      {/* otro */}
      <Link to='/permisoDeIngresoTratados' className={classes.link}>
        <Tooltip title='Permisos de Ingresos Tratados' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <ContentPasteIcon />
            </ListItemIcon>
            <ListItemText primary="Ingresos Tratados" />
          </ListItem>
        </Tooltip>
      </Link>
    </div>
  );
};

export const TertiaryListItems = () => { 
  const classes = useStyles();

  return (
    <div>
      <ListSubheader inset></ListSubheader>
        {/* otro */}
      <Link to='/barreras' className={classes.link}>
        <Tooltip title='Barreras' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <RemoveRoadIcon />
            </ListItemIcon>
            <ListItemText primary="Barreras" />
          </ListItem>
        </Tooltip>
      </Link>
      {/* otro */}
      <Link to='/vehiculosEnCamino' className={classes.link}>
        <Tooltip title='Vehiculos en camino' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <ListItemText primary="Vehiculos en camino" />
          </ListItem>
        </Tooltip>
      </Link>
    </div>
  );
};

export const QuaternaryListItems = () => {
  const classes = useStyles();

  return (
    <div>
      <ListSubheader inset>Reportes</ListSubheader>
      {/* otro */}
      <Link to='/accesosPeatonales' className={classes.link}>
        <Tooltip title='Reporte de ingresos/salidas' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <TransferWithinAStationIcon />
            </ListItemIcon>
            <ListItemText primary="Ingresos/salidas" />
          </ListItem>
        </Tooltip>
      </Link>
      {/* otro */}
      <Link to='/movimientoTotemsEntrada' className={classes.link}>
        <Tooltip title='Reporte de totems de entrada' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <LocationOnIcon color='success' />
            </ListItemIcon>
            <ListItemText primary={<Typography variant='body1' >Totems de entrada</Typography>} />
          </ListItem>
        </Tooltip>
      </Link>
      {/* otro */}
      <Link to='/movimientoTotemsSalida' className={classes.link}>
        <Tooltip title='Reporte de totems de salida' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <LocationOnIcon color='error' />
            </ListItemIcon>
            <ListItemText primary={<Typography variant='body1' >Totems de salida</Typography>}/>
          </ListItem>
        </Tooltip>
      </Link>
      {/* otro */}
      <Link to='/movimientoVehiculoTotemsEntrada' className={classes.link}>
        <Tooltip title='Reporte de entrada de vehiculos' arrow placement="right">
          <ListItem button>
            <ListItemIcon>
              <DirectionsCarIcon color='success' />
            </ListItemIcon>
            <ListItemText primary={<Typography variant='body1' >Entrada de vehiculos</Typography>} />
          </ListItem>
        </Tooltip>
      </Link>
    </div>
  );
};

export const SesionListItems = () => { 
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();

  const handeLogout = () => {
    removeCookie('Id_User');
    navigate('/login');
  }

  return (
    <div>
      <Tooltip title='Cerrar Sesión' arrow placement="right">
        <ListItem button onClick={handeLogout}>
          <ListItemIcon>
            <LogoutIcon color='info' />
          </ListItemIcon>
          <ListItemText primary={<Typography variant='body1' >Salir</Typography>}/>
        </ListItem>
      </Tooltip>
    </div>
  );
};