/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Tooltip, Typography } from "@mui/material";
import Dashboard from "../components/dashboard";
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import DatePicker from '@mui/lab/DatePicker';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchMovimientosTotem } from "../reducers/fetchWebService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { es } from "date-fns/locale";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() => 
createStyles({
  stickyHeader: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
  },
}),
);

const estiloRojo = {
  color: "white",
  backgroundColor: "red",
  padding: '5px 10px',
}

const estiloAmarillo = {
  color: "white",
  backgroundColor: "#FFA600",
  padding: '5px 10px',
}

const estiloVerde = {
  color: "white",
  backgroundColor: "green",
  padding: '5px 10px',
}


const MovimientoTotemsSalida = () => {
  const classes = useStyles();
  const lengthPrevia = useRef(0);
  const navigate = useNavigate();
  const { obra } = useSelector((state: RootState) => state.selectorObra)
  const [cargando, setCargando] = useState(true);
  const [movimientos, setMovimientos] = useState<any>([]);
  const [ ultimoRefresco, setUltimoRefresco ] = useState('');
  const hoy = new Date();
  const [hoyString, setHoyString] = useState<string>();
  const [fechaMovimientoDesde, setFechaMovimientoDesde] = React.useState<Date | null>(hoy);
  const [fechaMovimientoHasta, setFechaMovimientoHasta] = React.useState<Date | null>(hoy);
  const [fechaMovimientoDesdeString, setFechaMovimientoDesdeString] = useState<string>(hoyString);
  const [fechaMovimientoHastaString, setFechaMovimientoHastaString] = useState<string>(hoyString);
  const [dniBusqueda, setDniBusqueda] = useState<string>('');
  const [refrescarBusqueda, setRefrescarBusqueda] = useState<boolean>(true);
  const [cookies] = useCookies();
  const [rowSelectIndex, setRowSelectIndex] = useState<any>();
  
  //los totems pueden ir separados por coma, ej: '28,29,30' 
  const [totemsTraidos, setTotemsTraidos] = useState('');

  const setearTotemsSegunObra = () => {
    let totemsSalida = obra.Totems.filter((totem) => totem.Tipo === "S");
    let codigosTotems = [];
    totemsSalida.map(totem => (
      codigosTotems.push(totem.Id_Totem)
    ));
    setTotemsTraidos(codigosTotems.join());
    return codigosTotems.join();
  };

  const [orderColumn, setOrderColumn]= useState("Hora");
  const [orderDirection, setOrderDirection]= useState("desc");

  const parseFecha = fecha => new Date(
    parseInt(fecha.substring(6, 10)),
    parseInt(fecha.substring(3, 5)) - 1,
    parseInt(fecha.substring(0, 2)),
    parseInt(fecha.substring(11, 13)),
    parseInt(fecha.substring(14, 16)),
    parseInt(fecha.substring(17, 19))
  );

  const sortArray= (datos, columnName, columnOrder)=> {
    if (columnName == "Dni") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName] > b[columnName] ? 1 : b[columnName] > a[columnName] ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName] < b[columnName] ? 1 : b[columnName] < a[columnName] ? -1 : 0 
            );
      }
    }
    else if (columnName == "Hora") {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              parseFecha(a[columnName]) > parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) > parseFecha(a[columnName]) ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              parseFecha(a[columnName]) < parseFecha(b[columnName]) ? 1 : parseFecha(b[columnName]) < parseFecha(a[columnName]) ? -1 : 0 
            );
      }
    }
    else {
      switch (columnOrder){
        case "asc":
          default:
            return [...datos].sort ((a,b)=>
              a[columnName].trim() > b[columnName].trim() ? 1 : b[columnName].trim() > a[columnName].trim() ? -1 : 0
            );
        case "desc":
            return [...datos].sort((a, b)=>
              a[columnName].trim() < b[columnName].trim() ? 1 : b[columnName].trim() < a[columnName].trim() ? -1 : 0 
            );
      }
    }
  }

  const handleSortRequest = (columnName) => {
    var columnOrder = orderDirection === "asc"? "desc":"asc";

    if (columnName != orderColumn)
      columnOrder = "asc";

    var dataSorted = sortArray(movimientos, columnName, columnOrder);

    setMovimientos(dataSorted);

    setOrderColumn(columnName);
    setOrderDirection(columnOrder);
  }

  const leer = async () => {
    let totemsTemP = setearTotemsSegunObra();
    
    fechaHoyString();

    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    let payload = await fetchMovimientosTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, dniBusqueda, totemsTemP);
    lengthPrevia.current = payload.length;
    setCargando(true);
    setMovimientos([]);
    setMovimientos(payload);
    setCargando(false);
    setUltimoRefresco(new Date().toTimeString());
    let element = document.getElementById(rowSelectIndex.toString());
    element.scrollIntoView({behavior: "smooth", block: "end"});
  }

  const verificarNuevosDatos = async () => {
    let totemsTemP = setearTotemsSegunObra();
    let payload = await fetchMovimientosTotem(fechaMovimientoDesdeString, fechaMovimientoHastaString, dniBusqueda, totemsTemP);
    if(payload.length !== lengthPrevia.current){
      leer();
    }
  }

  useEffect(() => {
    fechaHoyString();
    leer();
    
    const interval = setInterval(() => {
      verificarNuevosDatos();
    }, 30000); // 30 seg
    return () => clearInterval(interval);
  }, [refrescarBusqueda, obra]);

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  const fechaHoyString = () => {
    let diaHoy = hoy.getDate();
    let mesHoy = hoy.getMonth();
    let yearHoy = hoy.getFullYear();

    if (mesHoy<10 && diaHoy<10){
      setHoyString(`0${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy>=10 && diaHoy<10){
      setHoyString(`0${diaHoy}/${mesHoy}/${yearHoy}`);
    }
    else if (mesHoy<10 && diaHoy>=10){
      setHoyString(`${diaHoy}/0${mesHoy}/${yearHoy}`);
    }
    else{
      setHoyString(`${diaHoy}/${mesHoy}/${yearHoy}`);
    }
  }
  
  const fechaMovimientoDesdeAString = ()=>{
    let dia = fechaMovimientoDesde.getDate();
    let mes = fechaMovimientoDesde.getMonth() + 1;
    let year = fechaMovimientoDesde.getFullYear();

    if (mes<10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/0${mes}/${year}`);
    }
    else if (mes>=10 && dia<10){
      setFechaMovimientoDesdeString(`0${dia}/${mes}/${year}`);
    }
    else if (mes<10 && dia>=10){
      setFechaMovimientoDesdeString(`${dia}/0${mes}/${year}`);
    }
    else{
      setFechaMovimientoDesdeString(`${dia}/${mes}/${year}`);
    }
  }

  const fechaMovimientoHastaAString = ()=>{
    let diaH = fechaMovimientoHasta.getDate();
    let mesH = fechaMovimientoHasta.getMonth() + 1;
    let yearH = fechaMovimientoHasta.getFullYear();

    if (mesH<10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/0${mesH}/${yearH}`);
    }
    else if (mesH>=10 && diaH<10){
      setFechaMovimientoHastaString(`0${diaH}/${mesH}/${yearH}`);
    }
    else if (mesH<10 && diaH>=10){
      setFechaMovimientoHastaString(`${diaH}/0${mesH}/${yearH}`);
    }
    else{
      setFechaMovimientoHastaString(`${diaH}/${mesH}/${yearH}`);
    }
  }

  //Pasa los metodos que pasan las fechas de Date a string para poder hacer la petición, 
  //luego llama al metodo que cambia el estado de refrescarBusqueda para que useEffect se active
  const fechasAString = () => {
    setCargando(true);
    fechaMovimientoDesdeAString();
    fechaMovimientoHastaAString();

    botonBuscarRefrescarBusqueda();
  }

  const botonBuscarRefrescarBusqueda = () => {
    if(refrescarBusqueda===true){
      setRefrescarBusqueda(false);
    }
    else if (refrescarBusqueda===false){
      setRefrescarBusqueda(true);
    }
  }

  const handleChangeDniBusqueda = e => {
    setDniBusqueda(e.target.value);
  }


  return (
    <Dashboard title="Movimiento Totems Salida" refresco={leer}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <Grid container spacing={0}>
        <FormControl fullWidth sx={{ display: "flex", flexDirection: 'row'}}>
          <Box sx={{ m: 1, width: '50%' }}>
            <InputLabel>Obra</InputLabel>
            <Select
              label="Obra"
              value={obra.Id_Zone.toString()}
              fullWidth
              disabled
            >
              <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
            </Select>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <TextField
              margin="none"
              fullWidth
              value={dniBusqueda}
              onChange={handleChangeDniBusqueda}
              id="dni"
              label="DNI"
              name="dni"
              autoComplete="dni"
            />
          </Box>
        </FormControl>
        <Stack sx={{ display: "flex", flexDirection: 'row', width: '100%' }}>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoDesde}
                  onChange={
                    (newValue) => {
                      setFechaMovimientoDesde(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          <Box sx={{ m: 1, width: '50%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <Stack sx={{ width: '100%' }}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaMovimientoHasta}
                  onChange={(newValue) => {
                    setFechaMovimientoHasta(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Stack>

        <Button onClick={()=>{fechasAString();}}>Buscar</Button>

        <TableContainer component={Paper} sx={{  overflow: 'auto', maxHeight: 700 }}>  
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={classes.stickyHeader}>
              <TableRow>
              <TableCell onClick={(e)=> handleSortRequest('Hora')}>
                  <TableSortLabel active={orderColumn == 'Hora'} direction={orderColumn != 'Hora' || orderDirection === "asc"? "asc" : "desc"}>
                  Hora
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Nombre')}>
                  <TableSortLabel active={orderColumn == 'Nombre'} direction={orderColumn != 'Nombre' || orderDirection === "asc"? "asc" : "desc"}>
                  Nombre
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell align="right" onClick={(e)=> handleSortRequest('Dni')}>
                  <TableSortLabel active={orderColumn == 'Dni'} direction={orderColumn != 'Dni' || orderDirection === "asc"? "asc" : "desc"}>
                  Dni
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Contratista')}>
                  <TableSortLabel active={orderColumn == 'Contratista'} direction={orderColumn != 'Contratista' || orderDirection === "asc"? "asc" : "desc"}>
                  Contratista
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Vigencia_Descripcion')}>
                  <TableSortLabel active={orderColumn == 'Vigencia_Descripcion'} direction={orderColumn != 'Vigencia_Descripcion' || orderDirection === "asc"? "asc" : "desc"}>
                  Estado
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Barrera')}>
                  <TableSortLabel active={orderColumn == 'Barrera'} direction={orderColumn != 'Barrera' || orderDirection === "asc"? "asc" : "desc"}>
                  Totem
                    </TableSortLabel>                    
                    </TableCell>
                    <TableCell onClick={(e)=> handleSortRequest('Motivo')}>
                  <TableSortLabel active={orderColumn == 'Motivo'} direction={orderColumn != 'Motivo' || orderDirection === "asc"? "asc" : "desc"}>
                  Requisito Faltante
                    </TableSortLabel>                    
                    </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!cargando && (movimientos.map((movimiento, index) => (
                  <TableRow
                    key={index}
                    id={index}
                    // selected={index === rowSelectIndex ? true : false}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {
                      setRowSelectIndex(index);
                    }}
                  >
                    <TableCell style={{ padding: '5px 10px' }} component="th" scope="row">
                      {movimiento.Hora}
                    </TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Nombre}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Dni}</TableCell>
                    
                    {movimiento.Contratista === "- Otra (Pendiente de alta)" 
                    ? <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2 }}>{movimiento.Contratista}</p></TableCell>
                    : <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Contratista}</TableCell>
                    }
                    
                    {movimiento.Vigencia_Descripcion === 'Apto' ?
                    <TableCell align="center" style={estiloVerde}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                    :
                    <TableCell align="center" style={estiloAmarillo}><p style={{ margin: 2 }}>{movimiento.Vigencia_Descripcion}</p></TableCell>
                    }

                    <TableCell style={{ padding: '5px 10px' }} align="right">{movimiento.Barrera}</TableCell>
                    <TableCell style={{ padding: '5px 10px' }} align="right">
                      <Box style={{ maxWidth: '15vw' }}>
                        <Tooltip title={movimiento.Motivo}>
                          <Typography noWrap align="left">{movimiento.Motivo}</Typography>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )))
              }
            </TableBody>
          </Table>
          {cargando && <Box sx={{
            width: '100%',
            height: 40,
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem 0rem',
          }}>
            <CircularProgress/>
          </Box>}
        </TableContainer>
        <Box style={{ marginTop: 10 }}>
          <Typography>Ultimos datos: {ultimoRefresco.slice(0, 8)}</Typography>
        </Box>
      </Grid>
    </Dashboard >
  );
}

export default MovimientoTotemsSalida;