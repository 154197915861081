import { Typography } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingBottom: 15,
    },
    copyright: {
      display:'flex', 
      flexDirection:'row', 
      justifyContent: 'center', 
      alignItems: 'center',
    }
  })
);

const Footer = () => {
  const classes = useStyles();
  let añoActual = new Date().getFullYear();

  return (
    <div className={classes.container}>
      <div className={classes.copyright}>
        <img src="./foot.png" alt="" style={{ height: '2rem', margin: '0px 5px 0px 0px' }} />
        <Typography variant="body2" color="textSecondary" align="center">
          {'COPYRIGHT © 2007-' + añoActual + ' | REALIZE GROUP | VIRTEC S.R.L.V4.12.4.0000'}
        </Typography>
      </div>
    </div>
  );
}

export default Footer;