import IngresoPeatonal from "../components/IngresoPeatonal";
import SalidaPeatonal from "../components/salidaPeatonal";
import Dashboard from "../components/dashboard";
import Barreras from "../components/barreras";
import PermisoDeIngresoPendiente from "../components/permisoIngresoPendientes";
import MovimientoVehiculoTotemsEntradaComponente from "../components/movimientoVehiculoTotemsEntrada";
import MovimientoTotemsEntradaComponente from "../components/movimientoTotemsEntrada";
import MovimientoTotemsSalidaComponente from "../components/movimientoTotemsSalida";
import AccesosPeatonalesComponente from "../components/accesosPeatonales";
import PermisoIngresoTratadosComponente from "../components/permisoIngresoTratados";
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../store';
import { addComponente, delAbierto, setColumasFull, setColumasMitad, setAllColumnas, setAllAbiertos } from "../reducers/componentesHome";
import { makeStyles, createStyles } from '@mui/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VehiculosEnCaminoComponente from "../components/vehiculosEnCamino";
import { useEffect, useState, useRef } from "react";
import { Grid, Chip, Stack } from "@mui/material";
import { cambiarRefresco } from "../reducers/refrescar";
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom';
import TrackVisibility from 'react-on-screen';
import { fetchContratistas } from "../reducers/fetchWebService";
import { setContratistas } from "../reducers/selectorObra";
import { reordenarComponentes } from "../helpers/reordenarComponentes";

const useStyles = makeStyles(() =>
  createStyles({
    myMasonryGrid: {
      width: '100%',
    },
    myMasonryGridColumn: {
      paddingLeft: 30, /* gutter size */
      backgroundClip: 'padding-box',
    },
    masonryItem: {
      marginBottom: 30,
    }
  })
);

const enum configTypes {
  userId,
  abiertos,
  columnas,
  componentes,
}

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  let refresco = false;
  const { obra } = useSelector((state: RootState) => state.selectorObra)
  const { abiertos, columnas, indexCookie } = useSelector((state: RootState) => state.mostradorComponentes)
  let componentesOrden = useRef(null);
  let abiertosRef = useRef([]);
  let columnasRef = useRef([ 6,6,6,6,6,6,6,6,6 ]);
  const [ compOrden, setCompOrden ] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']);
  const [ componentesArr, setComponentesArr ] = useState([
    { id: '1', titulo: 'Ingreso Peatonal', componente: <IngresoPeatonal handleHide={() => handleHide('1')} setColumnasFull={() => setColumnasFull(0)} setColumnasHalf={() => setColumnasHalf(0)} />},
    { id: '2', titulo: 'Barreras', componente: <Barreras handleHide={() => handleHide('2')} setColumnasFull={() => setColumnasFull(1)} setColumnasHalf={() => setColumnasHalf(1)} />},
    { id: '3', titulo: 'Salida Peatonal', componente: <SalidaPeatonal handleHide={() => handleHide('3')} setColumnasFull={() => setColumnasFull(2)} setColumnasHalf={() => setColumnasHalf(2)} />},
    { id: '4', titulo: 'Vehiculos En Camino', componente: <VehiculosEnCaminoComponente handleHide={() => handleHide('4')} setColumnasFull={() => setColumnasFull(3)} setColumnasHalf={() => setColumnasHalf(3)} />},
    { id: '5', titulo: 'Permisos Ingreso Pendientes', componente: <PermisoDeIngresoPendiente handleHide={() => handleHide('5')} setColumnasFull={() => setColumnasFull(4)} setColumnasHalf={() => setColumnasHalf(4)} />},
    { id: '6', titulo: 'Permisos Ingreso Tratados', componente: <PermisoIngresoTratadosComponente handleHide={() => handleHide('6')} setColumnasFull={() => setColumnasFull(5)} setColumnasHalf={() => setColumnasHalf(5)} />},
    { id: '7', titulo: 'Movimiento Totems Entrada', componente: <MovimientoTotemsEntradaComponente handleHide={() => handleHide('7')} setColumnasFull={() => setColumnasFull(6)} setColumnasHalf={() => setColumnasHalf(6)} />},
    { id: '8', titulo: 'Movimiento Totems Salida', componente: <MovimientoTotemsSalidaComponente handleHide={() => handleHide('8')} setColumnasFull={() => setColumnasFull(7)} setColumnasHalf={() => setColumnasHalf(7)} />},
    { id: '9', titulo: 'Accesos Peatonales', componente: <AccesosPeatonalesComponente handleHide={() => handleHide('9')} setColumnasFull={() => setColumnasFull(8)} setColumnasHalf={() => setColumnasHalf(8)} />},
    { id: '10', titulo: 'Entrada de Vehiculos', componente: <MovimientoVehiculoTotemsEntradaComponente handleHide={() => handleHide('10')} setColumnasFull={() => setColumnasFull(9)} setColumnasHalf={() => setColumnasHalf(9)} />}
  ]);
  
  const handleShow = async (id) => {
    await dispatch(addComponente(id));
  };

  const handleHide = async (id) => {
    await dispatch(delAbierto(id));
  };

  const setColumnasFull = async (id) => {
    await dispatch(setColumasFull(id))
  }
  
  const setColumnasHalf = async (id) => {
    await dispatch(setColumasMitad(id))
  }

  
  const [firstRender, setFirstRender] = useState(true);

  const checkCookie = () => {
    if (cookies.datosHome){
      //toma los datos de la cookie
      dispatch(setAllAbiertos(cookies.datosHome[indexCookie].abiertos));
      dispatch(setAllColumnas(cookies.datosHome[indexCookie].columnas));
      setComponentesArr(reordenarComponentes(cookies.datosHome[indexCookie].componentes, componentesArr));
      setFirstRender(false);
    } else if(!cookies.Id_User){
      navigateLogin();
    } else {
      handleCookies();
    }
  }

  const navigateLogin = () => {
    const referrerUrl = document.referrer;

    if (referrerUrl.includes('login.microsoftonline.com')) {
      navigate('/loginad');
    } else {
      navigate('/login');
    }
  }

  const handleCookies = () => {
    let nuevaConfig = cookies.datosHome;

    nuevaConfig[indexCookie].abiertos = abiertos;
    nuevaConfig[indexCookie].columnas = columnas;
    nuevaConfig[indexCookie].componentes = compOrden;

    setCookie(`datosHome`, nuevaConfig, {maxAge: 99999999});
  }

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const handleReorder = (result: any) => {
    const { source, destination } = result;
    setCompOrden(prevComp => reorder(prevComp, source.index, destination.index));
  }

  const handleOnDragEnd = (result: any) => {
    const { source, destination } = result;
    if(!destination){
      return;
    }
    if(
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }
    let reordenados = prevComp => reorder(prevComp, source.index, destination.index);
    setComponentesArr(reordenados);
    handleReorder(result);
  }

  const obtenerAntiRefresco = () => {
    refresco = !refresco;
    return refresco;
  }

  const setearContratistasSegunObra = async () => {
    try {
      let listadoContratistas = await fetchContratistas(obra.Id_Garmin_Group);
      let listadoContratistasOrdenadas = listadoContratistas.sort(function (a,b) {return a.Name.localeCompare(b.Name)});

      let listadoContratistasSelect = [];

      listadoContratistasOrdenadas.forEach(contratista => {
        listadoContratistasSelect.push({Id_Customer: contratista.Id_Customer, Name: contratista.Name})
      });

      dispatch(await setContratistas(listadoContratistasSelect));
    } catch (error) {
      
    }
  };

  useEffect(() => {
    if (firstRender === true){
      checkCookie();
    }
    else{handleCookies();}
  }, [abiertos,
    columnas,
    compOrden])

  useEffect(() => {
    setearContratistasSegunObra();
  }, [obra])

  useEffect(() => {
    const interval = setInterval(async () => {
      dispatch(await cambiarRefresco(obtenerAntiRefresco()));
    }, 10000); // 10 seg
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(!cookies.Id_User){
      navigateLogin();
    } else {
      checkCookie();
    }
  }, []);

  return (
    <Dashboard title="Sistema de Control de Accesos" refresco={null}>
      <DragDropContext onDragEnd={(result) => {handleOnDragEnd(result)}}>
      <Stack direction="row" spacing={1} sx={{ display: 'block', marginBottom: 3 }}>
        {componentesArr.map((comp) => (
          !abiertos.includes(comp.id) 
          ? <a key={comp.id} href={`#${comp.id}`} style={{textDecoration: 'none', margin: 0}}><Chip style={{ margin: '0px 0.6em 0.6em  0' }} variant="outlined" label={comp.titulo} key={comp.id} onClick={() => {handleShow(comp.id);  }} /></a>
          : <Chip style={{ margin: '0px 0.6em 0.6em  0' }} label={comp.titulo} key={comp.id} onClick={() => handleHide(comp.id)} />
        ))}
      </Stack>
        <Droppable droppableId="componentes">
          {(droppableProvider) => 
            <div
            {...droppableProvider.droppableProps} 
            ref={droppableProvider.innerRef}
            >
              <Grid 
                container 
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
                className={classes.myMasonryGrid}
              >
                {componentesArr.map((comp, i) => (
                  abiertos.includes(comp.id) &&
                  <Draggable key={comp.id} draggableId={comp.id} index={i}>
                    {(draggableProvider) => (
                      <Grid
                        item 
                        xs={12}
                        md={columnas[parseInt(comp.id)-1]}
                        {...draggableProvider.draggableProps} 
                        ref={draggableProvider.innerRef}
                        {...draggableProvider.dragHandleProps}
                        className={classes.masonryItem}
                        >
                        <TrackVisibility partialVisibility>
                          {comp.componente}
                        </TrackVisibility>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {droppableProvider.placeholder}
              </Grid>
            </div>
          }
        </Droppable>
      </DragDropContext>
    </Dashboard >
  );
}

export default Home;