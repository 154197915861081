import { createSlice } from "@reduxjs/toolkit";

export const barrerasSlice = createSlice({
  name: 'selectorBarreras',
  initialState: {
    barreras: [
      {Id: 0, Descripcion: '', Estado: '', Conexion: '', ComandoPendiente: ''}
    ],
  },
  reducers: {
    setBarreras: (state, action) => {
      state.barreras = action.payload;
    },
  }
})

export const { setBarreras } = barrerasSlice.actions;

export default barrerasSlice.reducer;