import { createSlice } from "@reduxjs/toolkit";

export const obraSlice = createSlice({
  name: 'selectorObra',
  initialState: {
    obra: {
      Id_Zone: 0,
      Name: "",
      Lat: "",
      Lon: "",
      VersionNumber: "3.1.1",
      Id_Garmin_Group: 0,
      Barreras_Dispositivos: "",
      Comando_Barrera_Abrir: "",
      Comando_Barrera_Cerrar: "",
      Comando_Barrera_DejarAbierta: "",
      Totems: [
        {
          Id_Totem: 0,
          Tipo: ""
        },
      ]
    },
    listadoObras: [
      {
        Id_Zone: 0,
        Name: "",
        Lat: "",
        Lon: "",
        VersionNumber: "3.1.1",
        Id_Garmin_Group: 0,
        Barreras_Dispositivos: "",
        Comando_Barrera_Abrir: "",
        Comando_Barrera_Cerrar: "",
        Comando_Barrera_DejarAbierta: "",
        Totems: [
          {
            Id_Totem: 0,
            Tipo: ""
          },
        ]
      },
    ],
    contratistas: [
      {
        Id_Customer: 0,
        Name: "",
        Id_Work_Status: 0,
        VersionNumber: 0
      },
    ],
  },
  reducers: {
    setObra: (state, action) => {
      state.obra = action.payload;
    },
    setListadoObras: (state, action) => {
      state.listadoObras = action.payload;
    },
    setContratistas: (state, action) => {
      state.contratistas = action.payload;
    },
  }
})

export const { setObra, setListadoObras, setContratistas } = obraSlice.actions;

export default obraSlice.reducer;