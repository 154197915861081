import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Divider, Grid, Stack, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { makeStyles, createStyles } from '@mui/styles';
import Dashboard from '../components/dashboard';
import { useSelector } from 'react-redux';
import type { RootState } from '../store';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { fetchGetDriver, fetchGetPersonalPermisoTratadoDni, fetchSetEntryPeatonal } from '../reducers/fetchWebService';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() =>
  createStyles({
    boxHeight: {
      height: '1em',
    },
    gridContainer: {
      marginBottom: 5,
    },
    estado: {
      padding: '0.7rem',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.38)',
      borderRadius: 4,
    }
  })
);

enum Estados {
  LIMPIO = '',
  APTO = 'APTO',
  NO_APTO = 'NO APTO',
  NO_APTO_PERMISO = 'NO APTO CON PERMISO',
  NO_CARGADO = 'NO CARGADO',
}

enum Tipos {
  PERSONAL = 'PERSONAL',
  INVITADO = 'INVITADO',
  PENDIENTE = 'PENDIENTE DE CARGA',
}

const estadoInicialForm = {
  dniIngresado: '',
  dni: 'escan',
  nombre: '',
  idDriver: 0,
  estado: Estados.LIMPIO,
  tipo: '',
};

const SalidaPeatonal = () => {
  const { obra } = useSelector((state: RootState) => state.selectorObra);
  const classes = useStyles();
  const [cookies] = useCookies();
  const [dniIngresado, setDniIngresado] = useState(estadoInicialForm.dniIngresado);
  const [dni, setDni] = useState(estadoInicialForm.dni);
  const [idDriver, setIdDriver] = useState(estadoInicialForm.idDriver);
  const [nombre, setNombre] = useState(estadoInicialForm.nombre);
  const [estado, setEstado] = useState(estadoInicialForm.estado);
  const [tipo, setTipo] = useState(estadoInicialForm.tipo);
  const fecha = new Date().toLocaleDateString();
  const navigate = useNavigate();


  const datosFetch = {
    fechaMovimiento: fecha + ' ' + new Date().toTimeString().slice(0, 8),
    idPersonal: idDriver,
    dniPersonal: dniIngresado,
    nombrePersonal: nombre,
    tipoMovimiento: 'S',
    TipoPeatonal: 1,
    idCustomer: 0,
    idJefeSupervisor: 0,
    obsPeatonal: '',
    accesoNombre: obra.Name,
    latPosicion: obra.Lat,
    lonPosicion: obra.Lon,
    idUser: cookies.Id_User,
    tipoEntrada: '',
  }

  const handleChange = (event) => {
    if(event.target.name === 'dni'){
      setDni(event.target.value);
    }
    if(event.target.name === 'dniIngresado'){
      if(event.key === 'Enter' || event.key === 13){
        event.preventDefault();
        if(dni !== 'escan'){
          handlePermisosDniManual();
        }else{
          leerDniEscaneado(event.target.value);
        }
      }
      if(dni !== 'escan'){
        setDniIngresado(event.target.value);
      }
    }
    if(event.target.name === 'nombre'){
      setNombre(event.target.value);
    }
  };

  const setDatosPersonal = async (dni?, nombreCompleto?) => {
    let dniLocal = dni? dni : dniIngresado;
    let resp =  await fetchGetDriver(dniLocal);
    if(resp.Id_Driver !== 0){
      setIdDriver(resp.Id_Driver);
      setNombre(resp.Name + ' ' + resp.Last_Name);
      setTipo(Tipos.PERSONAL);
      if(resp.Vigencia !== 2){
        setEstado(Estados.APTO);
      }else{
        setEstado(Estados.NO_APTO);
        let respGetPersonalTratadoDni = [];
        const [payload, error] = await fetchGetPersonalPermisoTratadoDni(dniLocal);
        if(!error){
          respGetPersonalTratadoDni = payload as any[];
        }
        if(respGetPersonalTratadoDni.length > 0){
          respGetPersonalTratadoDni.forEach(permiso => {
            if(permiso.Estado === 'APROBADO'){
              setEstado(Estados.NO_APTO_PERMISO);
              return;
            }
          });
        }
      }
    }else{
      setIdDriver(estadoInicialForm.idDriver);
      setNombre(dni == 'escan' ? nombreCompleto : estadoInicialForm.nombre);
      setEstado(Estados.NO_CARGADO);
      setTipo(Tipos.INVITADO);
    }
  }

  const leerDniEscaneado = (stringEscaner) => {
    let textoIngresado = stringEscaner;
    let chr_div = '';
    let nombre = '';
    let apellido = '';
    let dni = '';
    // let textoIngresado = `"28230270    "A"1"LEGUIZA"SANTIAGO ROBERTO"ARGENTINA"06-06-1980"M"07-02-2012"00093505359"7000 "07-02-2027"7"0"ILRÑ2.20 CÑ110927.01 )GM-EXE'MOVE'HM="UNIDAD #16 ]] S-NÑ 0040:2008::0021`
    // let textoIngresado = `00218959143@CANOVA@FACUNDO@M@25477038@A@09-08-1976@18-09-2013`

    if(textoIngresado.search("\"") !== -1){
      chr_div = "\"";
    }else{
      chr_div = "@";
    }

    let data = textoIngresado.split(chr_div);

    if(chr_div == '@'){
      dni = data[4];
      nombre = data[2];
      apellido = data[1];
    }else{
      dni = data[1];
      nombre = data[5];
      apellido = data[4];
    }

    let nombreCompleto = nombre + ' ' + apellido;
    setDniIngresado(dni);
    setDatosPersonal(dni, nombreCompleto);
  }


  const handlePermisosDniManual = () => {
    //aca entra si ingresa DNI manual y apreta el botón
    setDatosPersonal();
  }

  const limpiarFormulario = () => {
    setDniIngresado(estadoInicialForm.dniIngresado);
    setDni(estadoInicialForm.dni);
    setIdDriver(estadoInicialForm.idDriver)
    setNombre(estadoInicialForm.nombre);
    setEstado(estadoInicialForm.estado);
    setTipo(estadoInicialForm.tipo);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(estado === Estados.NO_CARGADO){
      if(nombre == ''){
        alert('Ingresar nombre completo');
      }else{
        await fetchSetEntryPeatonal(datosFetch);
        alert('Salida con éxito')
      }
    }else{
      await fetchSetEntryPeatonal(datosFetch);
      alert('Salida con éxito')
    }
  }

  useEffect(() => {
    if(!cookies.Id_User){
      navigate('/login')
    }
  }, []);

  return (
    <Dashboard title='Salida Peatonal' refresco={null}>
      <Button onClick={() => navigate('/')}><ArrowBackIcon fontSize="small" /> Volver</Button>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <form action="" onSubmit={(e) => handleSubmit(e)} style={{ minWidth: '80%' }}>
          <Box>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Obra
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Select
                  value={obra.Id_Zone.toString()}
                  fullWidth
                  disabled
                >
                  <MenuItem value={obra.Id_Zone}>{obra.Name}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={5}>
                <RadioGroup
                  row
                  name="dni"
                  style={{ flexWrap: 'nowrap' }}
                  defaultValue={dni}
                  onChange={handleChange}
                >
                    <FormControlLabel value="escan" control={<Radio checked={dni === "escan"} style={{ padding: 2 }} size="small" />} label={<Typography style={{ fontSize: '0.8rem' }}>Escaneo DNI</Typography>}/>
                    <FormControlLabel value="manual" control={<Radio checked={dni === "manual"} style={{ padding: 2 }} size="small" />} label={<Typography style={{ fontSize: '0.8rem' }}>Ingreso Manual</Typography>}/>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={2}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  {dni === 'escan' ? "Escanee el DNI":"Ingrese el DNI"}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {
                  dni === 'escan' 
                  ? (<TextField autoFocus onChange={(e) => handleChange(e)} onKeyDown={(e) => handleChange(e)} fullWidth name='dniIngresado' variant="outlined" size="small"/>)
                  : (
                    <Stack
                      direction="row"
                      spacing={2}
                    >
                      <TextField onChange={(e) => handleChange(e)} onKeyDown={(e) => handleChange(e)} name='dniIngresado' variant="outlined" size="small" />
                      <Button onClick={handlePermisosDniManual} variant="contained" size="small"><ManageSearchIcon /></Button>
                    </Stack>
                    )
                }
              </Grid>
            </Grid>
          </Box>

          <Paper variant="outlined" style={{padding: 10}}>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  DNI
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField disabled fullWidth value={dniIngresado} style={{backgroundColor: 'rgba(224,224,224,1)'}} variant="outlined" size="small"/> 
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Nombre y apellido
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField fullWidth disabled={estado === Estados.NO_APTO || estado === Estados.NO_CARGADO ? false : true} onChange={(e) => handleChange(e)} value={nombre} style={estado === Estados.NO_CARGADO ? {} : {backgroundColor: 'rgba(224,224,224,1)'}} name='nombre' variant="outlined" size="small"/>
              </Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Estado
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box 
                  className={classes.estado}
                  style={estado === Estados.APTO || estado === Estados.NO_APTO_PERMISO ? {backgroundColor: 'green'} : (estado === Estados.NO_APTO || estado === Estados.NO_CARGADO ? {backgroundColor: 'red'} : {minHeight: '2.5rem'})}>
                  <Typography color='white'>{estado}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container alignItems={'center'} spacing={2} className={classes.gridContainer}>
              <Grid item xs={3}>
                <Typography variant='h6' component='p' align='right' fontSize='0.9rem'>
                  Tipo
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField disabled fullWidth value={tipo} style={{backgroundColor: 'rgba(224,224,224,1)'}} name='tipo' variant="outlined" size="small"/> 
              </Grid>
            </Grid>
          </Paper>

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            justifyContent= 'center'
            spacing={2}
            style={{ marginTop: 15}}
          >
            <Button type='submit' variant="outlined" size="large" color="success" startIcon={<CheckIcon />}>Salida</Button>
            <Button variant="outlined" size="large" color="error" startIcon={<CancelIcon />}>Cancelar</Button>
            <Button onClick={() => limpiarFormulario()} variant="outlined" size="large" color="info" startIcon={<AutorenewIcon />}>Limpiar</Button>
          </Stack>
        </form>
      </div>
    </Dashboard>
  );
}

export default SalidaPeatonal;